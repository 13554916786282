import styled from '@emotion/styled';
import {
  ButtonFabPrimary,
  ChatOutlineMediumIcon,
  LinkWrapper,
  createMediaQuery,
  token,
} from '@ferreri/prever-component-lib';

import { SETTINGS } from '../../config/settings';

export const ChatButton = () => {
  return (
    <Container>
      <LinkWrapper href={SETTINGS.public.urlChatWeb}>
        <ButtonFabPrimary>
          <ChatOutlineMediumIcon />
        </ButtonFabPrimary>
      </LinkWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: calc(100vh - ${token.spacing.spacingMedium} - 48px - 70px);
  right: ${token.spacing.spacingMedium};
  z-index: 100;

  ${createMediaQuery({
    desktop: {
      position: 'unset',
      top: 'unset',
      right: 'unset',
      'align-self': 'flex-end',
      'z-index': 'unset',
    },
  })};
`;
