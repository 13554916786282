import styled from '@emotion/styled';
import {
  Skeleton,
  token,
  createMediaQuery,
} from '@ferreri/prever-component-lib';

type SkeletonSectionProps = {
  numberOfCards?: number;
};

export const SkeletonSection = (props: SkeletonSectionProps) => {
  const numberOfCards = props.numberOfCards || 2;
  const cardsArray = Array.from({ length: numberOfCards });

  return (
    <GridContainer>
      {cardsArray.map((_, index) => {
        return (
          <Skeleton height={148} width="100%" maxWidth={172} key={index} />
        );
      })}
    </GridContainer>
  );
};

const GridContainer = styled.div`
  gap: ${token.spacing.spacingSmall};
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${createMediaQuery({
    tablet: {
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap',
      alignItems: 'center',
    },
  })}
`;
